<template>
  <div>
    <div class="container pt-3">
      <div class="row">
        <!-- 結帳成功 -->
        <h4
          v-if="success"
          class="text-center mt-3 mb-4"
        >
          結帳成功，我們已經收到您的訂單。
        </h4>
        <!-- 結帳失敗 -->
        <h4
          v-else
          class="text-center mt-3 mb-4 text-danger"
        >
          結帳失敗，請至會員中心重新結帳。
        </h4>
      </div>
      <div class="mx-md-5 px-md-5">
        <div
          v-if="orderDetails.length>0"
          class="container p-5 shadow mb-4"
        >
          <div class="row my-4 text-start">
            <span class="col-6 col-md-2 offset-md-1 text-center">訂單編號</span>
            <span class="col-6 col-md-5">{{ order.orderID }}</span>
          </div>
          <hr>
          <div
            v-for="(ord,index) in orderDetails"
            :key="index"
            class="row align-items-center mb-5 mb-md-2"
          >
            <div class="col-6 col-md-2 offset-md-1">
              <img
                :src="ord.mainImg ?? require('@/assets/img/nopic.gif')"
                class="w-100"
              >
            </div>
            <div class="col-6 col-md-5 text-start">
              {{ ord.name }}
              <br>{{ ord.spec1Name?ord.spec1Name + '：' : '' }}{{ ord.spec1Value?ord.spec1Value: '' }}{{ ord.spec2Name? '，' + ord.spec2Name + '：' : '' }}{{ ord.spec2Value?ord.spec2Value: '' }}
            </div>
            <div class="d-block d-md-none w-100 my-2" />
            <div class="text-center offset-6 offset-md-0 col-3 col-md-1 col-1">
              {{ getThousands(ord.qty) }}
            </div>
            <div class="text-end col-3 col-md-1">
              {{ getThousands(ord.fixedPrice * ord.qty) }}
            </div>
          </div>
          <!--                     <hr> -->
          <!--                     <div class="row my-4"> -->
          <!--                         <span class="col-8 col-md-6 offset-0 offset-md-3">未稅金額</span> -->
          <!--                         <span class="col-4 col-md-1 text-end">{{getThousands(totalAmount)}}</span> -->
          <!--                     </div> -->
          <!--                     <div class="row my-4"> -->
          <!--                         <span class="col-8 col-md-6 offset-0 offset-md-3">稅額</span> -->
          <!--                         <span class="col-4 col-md-1 text-end">{{getThousands(tax)}}</span> -->
          <!--                     </div>                     -->
          <!--<div v-if="coupon.name && coupon.price"
                         class="row my-4">
                        <span class="col-6 offset-0 offset-md-3">使用折扣碼 <span class="d-inline-block">{{coupon.name}}</span></span>
                        <span class="col-4 col-md-1 text-end text-danger">- {{coupon.price}}</span>
                        <div class="text-center col-1 p-0">
                            <div><i data-feather="x"
                                   class="btn-icon mx-2"></i></div>
                        </div>
                    </div> -->

          <!--<div v-if="usedPoints > 0"
                         class="row my-4">
                        <span class="col-8 col-md-6 offset-0 offset-md-3">使用購物金折抵</span>
                        <span class="col-4 col-md-1 text-end text-danger">- {{usedPoints}}</span>
                        <div class="text-center col-1 p-0">
                            <div><i data-feather="x"
                                   class="btn-icon mx-2"></i></div>
                        </div>
                    </div> -->
          <!-- <div class="row my-4">
                        <span class="col-8 col-md-6 offset-0 offset-md-3">活動折抵 {{discount.name}}</span>
                        <span class="col-4 col-md-1 text-end text-danger">- {{discount.price}}</span>
                    </div>  -->
          <hr>
          <div class="row my-4 text-start">
            <span class="col-8 col-md-6 offset-0 offset-md-3">商品金額</span>
            <span class="col-4 col-md-1 text-end">{{ getThousands(order.amount) }}</span>
          </div>
          <div class="row my-4 text-start">
            <span class="col-8 col-md-6 offset-0 offset-md-3">運費</span>
            <span class="col-4 col-md-1 text-end">{{ getThousands(order.shipFee) }}</span>
          </div>
          <hr>
          <div class="row my-4 text-start">
            <span class="col-8 col-md-6 offset-0 offset-md-3">結帳金額</span>
            <span class="col-4 col-md-1 text-end">{{ getThousands(order.payAmt) }}</span>
          </div>
        </div><div
          v-if="orderDetails.length>0"
          class="container p-5 shadow"
        >
          <h4>收件資訊</h4>
          <hr>
          <div class="row my-4 text-start">
            <span class="col-md-2 offset-0 offset-md-3">運送方式</span>
            <span class="col-6">{{ order.server }}</span>
          </div>
          <div class="row my-4 text-start">
            <span class="col-md-2 offset-0 offset-md-3">收件人</span>
            <span class="col-6">{{ order.recipient }}</span>
          </div>
          <div class="row my-4 text-start">
            <span class="col-md-2 offset-0 offset-md-3">收件地址</span>
            <span class="col-6">{{ order.shipAddr }}</span>
          </div>
          <div class="row my-4 text-start">
            <span class="col-md-2 offset-0 offset-md-3">收件人手機</span>
            <span class="col-6">{{ order.cell }}</span>
          </div>
          <div class="row my-4 text-start">
            <span class="col-md-2 offset-0 offset-md-3">收件人Email</span>
            <span class="col-6">{{ order.email }}</span>
          </div>
          <div class="row my-4 text-start">
            <span class="col-md-2 offset-0 offset-md-3">訂單備註</span>
            <span class="col-6">{{ order.memo }}</span>
          </div>
        </div>
        <!-- 結帳成功 -->
        <div
          v-if="success"
          class="mx-auto my-4 text-center"
        >
          <router-link
            class="btn btn-primary text-white btn-lg rounded-0"
            to="/product/list"
          >
            看更多商品
          </router-link>
        </div>
        <!-- 結帳失敗 -->
        <div
          v-else
          class="mx-auto my-4 text-center"
        >
          <p class="text-danger small">
            注意：此筆訂單尚未完成訂購，請重新結帳！
          </p>
          <router-link
            class="btn btn-primary text-white btn-lg rounded-0"
            to="/member/orders"
          >
            重新結帳
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex'
import { inject, computed, ref } from 'vue'
export default {
  name: 'OrderConfirm',
  components: {

  },
  async setup () {
    const { dispatch, getters, commit } = useStore()

    const orderID = computed(() => getters['order/getOrderID'])
    const order = computed(() => getters['order/getOrder'])

    const setAlert = inject('setAlert')

    const orderDetails = order.value?.orderDetails || []

    const getThousands = (num) => {
      return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0
    }
    const success = ref(false)
    const readOrder = async () => {
      const payload = { orderID: orderID.value }

      try {
        const response = await dispatch('order/readOrder', { payload })
        success.value = response.data.code === 201 || response.data.code === 200
        commit('order/setOrderID', { data: null })
      } catch (error) {
        // console.log('get user info error: ' + ', ' + error)
        setAlert(true, false, error)
      }
    }

    await readOrder()

    return {
      getThousands, orderDetails, order, success
    }
  }
}
</script>
<style lang="scss" module>

</style>
